import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

export interface ImageDoc {
  createdAt: object;
  url: string;
  id: string;
  name?: string;
}

const useFirestore = (collection: string) => {
  const [docs, setDocs] = useState<ImageDoc[]>([]);

  useEffect(() => {
    const unsubscribe = projectFirestore.collection(collection)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snap) => {
        let documents: any[] = [];
        snap.forEach(doc => {
          documents.push({...doc.data(), id: doc.id});
        });
        setDocs(documents);
      });

    return () => unsubscribe();

  }, [collection]);

  return { docs };
}

export default useFirestore;
