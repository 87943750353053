import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ImagePlaceholder from './ImagePlaceholder';
import { ImageDoc } from '../hooks/useFirestore';

const Image = ({ doc }: { doc:ImageDoc }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  }

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ImagePlaceholder />
        </motion.div>
      )}
      <motion.img
        src={doc.url}
        alt={doc.name || 'uploaded pic'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        onLoad={handleImageLoaded}
        transition={{ delay: 0.5 }}
      />
    </AnimatePresence>
  )
}

export default Image;
