import React from 'react';
import { motion } from 'framer-motion';
import useFirestore from '../hooks/useFirestore';
import Image from './Image';

const ImageGrid = ({ setSelectedImg }: any) => {
  const { docs } = useFirestore('images');

  return (
    <div className="img-grid">
      {docs && docs.map(doc => (
        <motion.div
          className="img-wrap"
          key={doc.id}
          onClick={() => setSelectedImg(doc.url)}
          layout
          whileHover={{ opacity: 1 }}
        >
          <Image doc={doc} />
        </motion.div>
      ))}
    </div>
  )
}

export default ImageGrid;
