import React from 'react';
import { motion } from 'framer-motion';

interface Modal {
  selectedImg?: string;
  setSelectedImg: (any);
}

const Modal = ({ selectedImg, setSelectedImg }: Modal) => {
  const handleClick = (e: any) => {
    if (e.target.classList.contains('backdrop')) {
      setSelectedImg('');
    }
  };

  return (
    <motion.div
      className="backdrop"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.img
        src={selectedImg}
        alt="enlarged pic"
        initial={{ y: '-100vh' }}
        animate={{ y: 0 }}
      />
    </motion.div>
  )
}

export default Modal;
