import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyD4Qi2CL3TFZlPdI6Zm4MDHFJHOYHJKjvw",
  authDomain: "jayakornk-firegram.firebaseapp.com",
  databaseURL: "https://jayakornk-firegram.firebaseio.com",
  projectId: "jayakornk-firegram",
  storageBucket: "jayakornk-firegram.appspot.com",
  messagingSenderId: "748735475093",
  appId: "1:748735475093:web:5579fb1ade9d848271da9c"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage =firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
